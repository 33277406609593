import Continueconvo from "../../components/continueconvo";

function Milestones() {
    return (
        <div class="container">

            <br></br>

            <div class="row"> 

                <div class="col">
                <div class="container">
                    <p><br></br><h1>Milestones {">"} Numbers</h1><br></br></p>
                    <p class="fs-6 text-muted">
                    - December 2022
                    </p>
                </div>
                </div>
                <div class="col-2">
                <br></br>
                    <img src={process.env.PUBLIC_URL+"/lwv.jpg"} class="img-fluid" alt="milestones"></img>
                <br></br>
                <br></br>
                </div>  
            </div>

            <hr class="my-3"></hr>


            <div class="container">
                <br></br>
                <p>
                    I’m always looking to understand what drives successful outcomes. As we come out of a bull run and search for the next source of growth, I’ve been reflecting on what made the winners of the last decade. There were big moves that have transformed industries and speculative moves that are yet to pay off. While it’s much easier in hindsight to identify what made the winners, I think there are valuable heuristics to be drawn. One that has proved valuable to me is attention to what I refer to as milestones.
                </p>
                <br></br>
                <h3>Defining a milestone</h3>
                <p>
                    A milestone more generally is defined as ‘a significant stage or event in the development of something’. In this case, I’m specifically referring to the point where a trend can now be described by a binary or category rather than a continuous trend.
                    <br></br><br></br>
                    To give just a few examples, a milestone is:
                    <br></br>
                    <ul>
                        <li>When your phone’s battery life lasts long enough to last ‘all day’</li>
                        <li>When your media files can be served fast enough to ‘stream’ in ‘real time’</li>
                        <li>When your media service contains ‘the popular artists or movie’ rather than an ever-larger count in it's catalogue</li>
                    </ul>
                </p>
                <br></br>
                <h3>Why it matters</h3>
                <p>
                    The point I would make is that while capital and effort are often poured broadly into popular trends, these tend to yield only incremental results. More importantly, incremental results don’t tend to win markets. What wins over customers are meaningful milestones that bring noticeable changes to their experience. 
                    <br></br><br></br>
                    This isn’t an entirely new concept; there’s a reason its so common to focus on releasing an MVP, discuss going from 0 to 1, or work from first principles. Whereas those frameworks describe a market entry approach, an approach to competition, and an approach to innovation; milestones are the limits to prioritise and optimise across all the factors that go into a product. What I’m trying to do is describe something that ties them together. With limited resources to put into a product, sometimes doing more in the wrong area can mean you fall short of what matters. If you can relate these localised frameworks to something broader, you can ask the right questions and make the globally optimised decisions. Its more of a tool to relabel something across domains so that you can reframe and compare them.
                    <br></br><br></br>
                    I’ll write more about building and applying frameworks in later posts but here I want to focus more on examples from the the last decade.
                </p>
                <br></br>
                <h3>Examples from the market</h3>
                <p>
                    I’ve put together some examples from the market to view through this lens. It’s not that other frameworks don’t apply but more an experiment of this perspective across areas like market competition, product design, and marketing.
                </p>
                <h5>Apples's move to Arm with M1</h5>
                <p>
                    One of the biggest moves in the tech industry of the last decade was apples move to its own ARM-based chips for its laptop and desktop devices. A bold move but one most would agree has been a decisive success. Initially it wowed with benchmarks, and while the x86 competition has now caught up on that front, there’s a reason the M-series will continue to be a favourite among consumers. They did away with ever higher benchmark scores and focused on performance per watt. The performance is as 'fast' as any typical user will ever need, specialist hardware ensures that 'media editing' competes in its own category, battery life easily 'lasts a day', devices can be 'fanless'. In other words, it just does all the things a typical customer would need it to do. Rather than score ever higher numbers in generalist benchmarks unimportant to most users, Apple made the decisions that optimised for categorical facts that’s do.
                </p>
                <h5>Apple’s marketing and design</h5>
                <p>
                    Apple as a company do this on a consistent basis, you just have to look at their key product and marketing decisions. One example is their ‘Retina Display’ branding. When building a product, limiting the resolution to the point where a user can no longer see the difference is the only category that matters to a user’s experience. This allows the product to be optimised for other aspects like battery life and cost (admittedly the surplus tends to land with the producer here). This does represent a marketing challenge as competitors bring out ever higher standards like FHD or 4K. However, Apple has rewritten that continuous trend into a categorical feature and have the brand and marketing prowess to ensure their customers rightfully trust it.
                </p>
                <h5>Smartphones</h5>
                <p>
                    Looking more broadly at the mobile space, we’re now seeing a multitude of brands going for mid-range pricing points for their flagship devices. We’re also seeing customers buy new phones less frequently. For most consumers, these mid-range devices tick all boxes. Bar mobile gamers, a mid-range chip will run ‘everything’ and do it ‘instantaneously’ from our user experience perspective. The remainder will categorically want the best camera for moments that will only happen once, to be able to play any game, or just the novelty of being in the category of best. The market itself is increasingly defined by these categorical limits.
                </p>
                <h5>Tesla’s beeline for EV viability </h5>
                <p>
                    Tesla will be a case study for years to come on how to transform an industry. They aren’t the first to try but no doubt will be credited for making the change happen. The milestone here was simply making an EV viable for the average person. What that took was a series of other milestones that Tesla very directly targeted at the point of viability. When you have to simultaneously price a car from $35,000, achieve 250 miles of range, and build a charging network enabling inter-state travel, you will have to make some concessions. In a market where it was never going to be easy, this focus on what matters is arguably why they succeeded. Tesla did away with the endless list of incremental details and prioritised the one milestone that weighed far heavier for consumers.
                </p>
                <h5>Social media</h5>
                <p>
                    You can build a technically better social media platform, but you can’t substitute the networks that make them work. You either have all your friends or content creators on a platform or you don’t. The same binary applies for content format. Users only have a limited amount of time to use each platform, anything that falls short of a user’s attention span appears to be substituted for another. Now both milestones are a factor to users, but it increasingly seems that as far as content is concerned, it’s the latter that wins.
                </p>
                <h5>Streaming services</h5>
                <p>
                    On streaming services, users don’t subscribe because you have a slightly larger catalogue of generic licenced content. Users subscribe because you categorically have it at all. More importantly, users subscribe because you have the specific content or exclusive they want to see. Both the new entrants and incumbents in this market have heavily relied on this, you only have to look at the scale of investment in exclusive rights to content makers and franchises.
                </p>
                <br></br>
                <h3>Milestones as boundaries</h3>
                <h5>Blockchain-based products</h5>
                <p>
                    With web3 and blockchain-based products, you face consistent trade-offs with centralised web platforms. As a result, it would follow that use cases are tied to the incremental updates and changes to the underlying compute and networking infrastructure the internet is built on. I absolutely think that blockchain use cases will continue to grow but without paying attention to those limits as milestones, the effort put in will produce bottlenecked results. The higher-level milestone to look at is what makes the difference to users; the fact it is decentralised, or that benefits of centralisation (such as speed) matter more.
                </p>
                <h5>Gaming</h5>
                <p>
                    In the case of gaming, you can come up with the best ideas, but they will never be viable unless they comply with the hardware available to end users. This hardware has a relatively fixed and predictable development timeline. Semiconductor nodes, architectures, and chips are planned on a comparatively long-term basis, and this is before you get to price points and device types available to users. These milestones can define a market segment and the viability of a title long before the strength of the content can be tested.
                </p>
                <h5>Digital Technology</h5>
                <p>
                    Much like the gaming industry, all digital technology is limited by hardware. While there are often enough permutations of a product to make it impossible to predict a single avenue for success; you can more easily conclude what to discount at a point in time by looking at milestones in hardware.
                </p>
                <br></br>
                <h3>Implications</h3>
                <p>
                    At the point where an aspect of a product becomes commoditised, there is little to gain from pursuing it further. Its more likely you’ll need to look for the next area of differentiation, so it helps to scan the trends and ask what the most significant boundary is to break. Considering the vast technology and business landscape still in speculation, at a point in time where we will have to be more measured in approach, it helps to draw some guidelines to navigate the options ahead.
                </p>

                <br></br>   
            </div>

            <Continueconvo />

            <div class="container">
                <p class="text-muted" style={{ 'font-size':'70%' }}>
                The views expressed here are those of the auther and do not necessarily represent the views of any affiliated individuals or entities. 
                The authors and/or any associates of this site assume no responsibility or liability for any errors or omissions in the content of this site and cannot be responsible for any use of the information contained on this site.
                </p>
            </div>



        </div>

    );
  }
  
  export default Milestones;