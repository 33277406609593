function Header() {
    return (
      <div className="Header">
            <header>
                <div className="container">
                    <a className="nav-link px-2 text-white" href="/" style={{ lineHeight: 'inherit' }}>
                        <span className="float-md-start fs-5 mt-1 mb-0">Z37K</span>
                    </a>
                    <nav className="nav nav-masthead justify-content-center float-md-end">
                        <a href="/blog" className="nav-link px-2 text-white">Blog</a>
                        <p className="nav-link px-2 text-white"> | </p>
                        <a href="/about" className="nav-link px-2 text-white">About</a>
                    </nav>
                </div>
            </header>
      </div>
    );
}

export default Header;
