import { twitterlink } from "../config";

function Footer() {
    return (
      <div className="Footer mt-auto">
        <footer class="mt-auto">
            <div class="container">
            <div class="row">
                <div class="col">
                <p class="text-start">© 2022 Z37K</p>
                </div>        

                <div className="col footerend">
                  <div>
                    <a className="twitter-follow-button" data-show-count="false" target="_blank" rel="noopener noreferrer" href={twitterlink}>
                      <img src={process.env.PUBLIC_URL+"/icons/twittericon.svg"} className='socialicons' alt=""></img>
                    </a>
                    <a className="" href="https://github.com/Z37K" target="_blank" rel="noopener noreferrer">
                      <img src={process.env.PUBLIC_URL+"/icons/githubicon.svg"} className='socialicons' alt=""></img>
                    </a>
                  </div>
                  <div>
                    <p class="text-end">&nbsp;&nbsp;_</p>
                  </div>
                </div>
            </div>
            <br></br>
            </div>
        </footer>
        </div>
    );
  }
  
  export default Footer;