function Blog() {
    return(
        <div className="container-fluid">

  <br></br>

  <div className="container">
      <p><br></br><h1><b>Thoughts</b>, <u>articles</u> and <i>stories</i></h1><br></br></p>
  </div>

{
        // The buttons
}
  <div className="container">
    <div className="row">
      <div className="col">
        <div className="fs-4">
          <br></br>
          <p>Index</p>
          <br></br>
        </div>
      </div>
    </div>
  </div>

{
    // The content areas
}
  <div className="container">
    {
      // Blog - Index
    }
    
      <div className="row">
        <div className="col">
          <hr className="my-3"></hr>
          <br></br>
        </div>
        <div className="col">
        </div>
      </div>

      {
        // Milestones
      }
      <div className="row"> 
        <div className="col-1">
          <a href="blog/collectibles">
            <img src={process.env.PUBLIC_URL+"/lwv.jpg"} className="img-fluid" alt="milestones"></img>
          </a>
        </div>  
        <div className="col">
          <a href="blog/milestones" className="link-light">
          <p className="fs-5">
          Milestones {">"} Numbers
          </p>
          </a>
          <p className="fs-6 text-muted">
            - December 2022
          </p>
        </div>      
      </div>

      {
        // Separator
      }
      <br></br>

      {
        // Collectibles
      }
      {/* 
      <div className="row"> 
        <div className="col-1">
          <a href="blog/collectibles">
            <img src={process.env.PUBLIC_URL+"/RE.jpg"} className="img-fluid" alt="collectibles"></img>
          </a>
        </div>  
        <div className="col">
          <a href="blog/collectibles" className="link-light">
          <p className="fs-5">
            Hacking a niche collectibles market
          </p>
          </a>
          <p className="fs-6 text-muted">
            - August 2022
          </p>
        </div>
      </div>
      */}
  </div>
</div>
    )
}

export default Blog;