import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from "./pages/home";
// import About from "./pages/about";
import Blog from "./pages/blog";
// import Collectibles from "./pages/blog/collectibles";
import Milestones from "./pages/blog/milestones";

function App() {
  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <hr></hr>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="about" element={<Home />}/>
          <Route path="blog" element={<Blog />}/>
            <Route path="blog/milestones" element={<Milestones />}/>
          {/*}
            <Route path="blog/collectibles" element={<Collectibles />}/> 
          <Route path="collectibles" element={<Collectibles />}/>
          */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
