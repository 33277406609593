import { twitterHandle, twitterlink } from "../config";

function Home() {
    return (
        <div class="container-fluid justify-content-center">

        <br></br>      

            <div class="homecontainer">

                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tg60.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons">-<br></br><br></br><br></br><br></br>.</div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tr80.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text lora">Hi, I'm ZK.
                        <p class="overlay-text-secondary">
                            <br></br>
                            I explore, understand, & create.
                            <br></br>
                            This is a space to share that experience.
                            <br></br>
                            <br></br>
                            Reach out at:
                            <br></br>
                             <a href="mailto:contact@z37k.com">contact@z37k.com</a> | <a className="twitter-follow-button" data-show-count="false" target="_blank" rel="noopener noreferrer" href={twitterlink}>Follow {twitterHandle}</a>
                        </p>
                    </div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tp50.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons text-end">¬ <br></br><br></br><br></br><br></br>..</div>
                </div>
            </div>

        <br></br>

        </div>
    );
  }
  
  export default Home;